import classNames from "classnames";
import React from "react";
import Card from "../../components/Card/Card";
import TagInfo from "../../components/TagInfo/TagInfo";
import { SanityEcosystemMember } from "../../model/common";
import { imageUrlBuilder } from "../../utils/images";
import { organisationUrl } from "../../utils/organisation-util";
import LinkOverlayContainer from "../LinkOverlayContainer/LinkOverlayContainer";
import SupplierCardPart, {
  ResellerType
} from "../SupplierCardPart/SupplierCardPart";
import Text from "../Text/Text";
import styles from "./ResellerCard.module.scss";

interface EcosystemMemberCardProps {
  value: SanityEcosystemMember;
  // Temporary, we can inline this.
  tags: (value: SanityEcosystemMember) => JSX.Element[][];
  className?: string;
  productSlug: string | undefined;
  type: ResellerType;
}

const ResellerCard = ({
  value,
  tags,
  className,
  productSlug,
  type
}: EcosystemMemberCardProps) => {
  const { name, logo } = value;
  const resellerUrl = organisationUrl(value, "reseller")!;
  // Replace non-breaking spaces to prevent overflow. Ideally we'd do this to the source data!
  const tidyName = name.replace(/\u00a0/g, " ");
  const width = Math.min(165, logo.asset.metadata.dimensions.width);
  const url =
    value.resellerProducts.find(p => p.product.slug.current === productSlug)
      ?.productUrl ?? resellerUrl;
  return (
    <LinkOverlayContainer>
      <Card className={classNames(styles.root, className)} dir="ltr">
        <div className={styles.header}>
          <SupplierCardPart name={tidyName} url={url} type={type} />
          <img
            loading="lazy"
            src={
              imageUrlBuilder
                .image(logo)
                .width(width)
                .fit("fill")
                .bg("fff")
                .quality(100) // originals are really poor
                .url()!
            }
            alt="logo"
          />
        </div>
        <Text variant="h2">{tidyName}</Text>
        <TagInfo value={tags(value)} />
      </Card>
    </LinkOverlayContainer>
  );
};

export default ResellerCard;
