import React from "react";
import { Col, Row } from "../../components/Grid";
import { SanityEcosystemMember } from "../../model/common";
import ResellerCard from "../ResellerCard/ResellerCard";
import styles from "./ResellerListing.module.scss";
import { ResellerType } from "../SupplierCardPart/SupplierCardPart";

interface ResellerListingProps {
  resellers: SanityEcosystemMember[];
  cardTags: (value: SanityEcosystemMember) => JSX.Element[][];
  productSlug?: string | undefined;
  resellerType: ResellerType;
}

const ResellerListing = ({
  resellers,
  cardTags,
  productSlug,
  resellerType
}: ResellerListingProps) => {
  const midpoint = Math.ceil(resellers.length / 2);
  const leftResellers = resellers.slice(0, midpoint);
  const rightResellers = resellers.slice(midpoint, resellers.length);
  return (
    <Row className={styles.cards} justify="center">
      <Col xs={12} sm={6} md={5}>
        {leftResellers.map(reseller => (
          <ResellerCard
            type={resellerType}
            key={reseller._id}
            className={styles.card}
            value={reseller}
            tags={cardTags}
            productSlug={productSlug}
          />
        ))}
      </Col>
      <Col xs={12} sm={6} md={5}>
        {rightResellers.map(reseller => (
          <ResellerCard
            type={resellerType}
            key={reseller._id}
            className={styles.card}
            value={reseller}
            tags={cardTags}
            productSlug={productSlug}
          />
        ))}
      </Col>
    </Row>
  );
};

export default ResellerListing;
