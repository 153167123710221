import React from "react";
import GetText from "../../i18n/GetText";
import ExternalLinkIcon from "../../icons/ExternalLinkIcon";
import LinkOverlay from "../LinkOverlay/LinkOverlay";
import Text from "../Text/Text";
import styles from "./SupplierCardPart.module.scss";

export type ResellerType = "reseller" | "channel-partner" | "accessory";

interface SupplierCardPartProps {
  name: string;
  url?: string;
  type: ResellerType;
}

const SupplierCardPart = ({ name, url, type }: SupplierCardPartProps) => (
  <div className={styles.root}>
    <Text variant="defaultTiny" as="div">
      {type === "channel-partner" && (
        <GetText id="channel-partner" fallback="Channel partner" />
      )}
      {type === "reseller" && <GetText id="reseller" fallback="Reseller" />}
      {type === "accessory" && <GetText id="accessory" fallback="Accessory" />}
    </Text>
    {url ? (
      <LinkOverlay
        to={url}
        rel={
          process.env.GATSBY_STAGE === "PRODUCTION" ? "noopener" : "noreferer"
        }
      >
        <Text variant="default" as="span">
          {name}
        </Text>
        {url && <ExternalLinkIcon />}
      </LinkOverlay>
    ) : (
      <Text variant="default" as="span">
        {name}
      </Text>
    )}
  </div>
);

export default SupplierCardPart;
